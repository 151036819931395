<template>
    <div class="chart_card">
        <div class="header">
            <h4 class="title">{{ title }}<span>{{ currency }}{{ scores.total }}{{ ending }}</span></h4>
            <div class="info">
                <i class="fas fa-info"></i>
                <div class="quick_info">
                    {{ info }}
                </div>
            </div>
        </div>
        <vue-echarts :option="option" style="height: 100px" ref="chart"></vue-echarts>
    </div>
</template>

<script>
import { VueEcharts } from 'vue3-echarts';

export default {
    name: 'ChartCard',

    data () {
        return {
            option: {
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        return params[0].name + ': ' + params[0].value;
                    }
                },
                grid: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                },
                xAxis: {
                    show: false,
                },
                yAxis: {
                    show: false,
                },
                series: [
                    {
                        symbol: 'none',
                        data: [],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: 'rgba(47,127,237,0.15)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(58,77,233,0)'
                                    }
                                ],
                                global: false
                            }
                        },
                        lineStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#2F7FED'
                                    },
                                    {
                                        offset: 1,
                                        color: '#88CBEE'
                                    }
                                ],
                                global: false
                            }
                        }
                    }
                ]
            },
        }
    },

    components:{
        VueEcharts
    },

    props: {
        title: String,
        scores: {
            type: Object,
            default: () => { return { total: 0, axis: [], data: [] }; }
        },
        info: String,
        currency: {
            type: String,
            default: ''
        },
        ending: {
            type: String,
            default: ''
        },
    },

    watch: {
        scores (scores) {
            const vm = this;

            vm.resetChart();
        },
    },

    mounted () {
        const vm = this;

        setTimeout(function () {
            vm.resetChart();
        }, 1000);
    },

    methods: {
        resetChart () {
            const vm = this;

            vm.option.xAxis.data = vm.scores.axis;
            vm.option.series[0].data = vm.scores.data;
            vm.option.tooltip =  {
                trigger: 'axis',
                formatter: (params) => params[0].name + ': ' + vm.currency + params[0].value + vm.ending
            };

            vm.$refs.chart.refreshChart();
        },
    },

}
</script>

<style scoped>
    .chart_card {
        background: #fff;
        border-radius: 10px;
        border: 1px solid #f5f5f5;
    }

    .header {
        padding: 15px 20px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .title {
        font-size: 11px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        text-align: left;
        text-transform: uppercase;
    }

    .title span {
        font-size: 20px;
        font-weight: 300;
        color: #5a5a5a;
        display: block;
        padding-top: 7px;
    }

    .info {
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e9e9e9;
        border-radius: 50%;
        font-size: 10px;
        color: #121525;
        margin: 0;
        position: relative;
        cursor: pointer;
    }

    .info .quick_info {
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        width: 170px;
        text-align: center;
        position: absolute;
        bottom: 100%;
        right: -6px;
        margin-bottom: 10px;
        display: none;
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 7px 10px;
        z-index: 1;
        text-align: left;
    }

    .info .quick_info:after {
        content: "";
        position: absolute;
        bottom: -6px;
        right: 10px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }

    .info:hover .quick_info {
        display: block;
        animation: smoothMove 0.5s;
    }

    @keyframes smoothMove {
        0% {
            bottom: 30%;
            opacity: 0;
        }

        10% {
            bottom: 30%;
            opacity: 0.5;
        }

        100% {
            bottom: 100%;
            opacity: 1;
        }
    }
</style>